import classNames from 'classnames';
import { type ElementType, forwardRef, type ReactNode, type Ref } from 'react';

import type {
  Appearance,
  AppearanceProps,
  AppearanceSocial,
  AppearanceStatus,
} from '../../utils/appearance';
import type {
  AsProps,
  ComponentWithAsProp,
  ReplaceProps,
} from '../../utils/as';
import type { Size } from '../../utils/size';
import { Icon, type IconName } from '../Icon';
import { Loader } from '../Loader';
import type { Theme } from '../ThemeProvider';

import styles from './Button.module.css';

type ButtonAppearance = Appearance | AppearanceStatus | AppearanceSocial;

const sizeStyles = {
  s: styles.button_s,
  l: styles.button_l,
};

const ICON_SIZES = {
  s: 16,
  l: 20,
};

const mappedStyles: Record<ButtonAppearance, string | undefined> = {
  primary: styles.button_primary,
  secondary: styles.button_secondary,
  default: styles.button_default,
  tertiary: styles.button_tertiary,
  accented: styles.button_accented,
  success: styles.button_success,
  error: styles.button_error,
  github: styles.button_github,
  google: styles.button_google,
  neon: styles.button_neon,
  hasura: styles.button_hasura,
  microsoft: styles.button_microsoft,
  warning: undefined,
  loading: undefined,
  clear: styles.button_clear,
};

export interface ButtonProps<As extends ElementType = ElementType>
  extends AsProps<As>,
    AppearanceProps<ButtonAppearance> {
  /**
   * Button's size
   * @default 'l'
   */
  size?: Exclude<Size, 'm'>;

  /**
   * Makes the button fill the width of its container.
   * @default false
   */
  wide?: boolean;

  /**
   * Icon to show in the button.
   */
  icon?: IconName;

  /**
   * Icon theme for when the default doesn't look good.
   */
  iconTheme?: Theme;

  /**
   * Disables the button and shows a loading spinner.
   */
  loading?: boolean;

  /**
   * Controls the position of the icon, if there is one.
   * @default 'left'
   */
  iconSide?: 'left' | 'right';

  /**
   * Disables the button, disallowing user interaction.
   * @default false
   */
  disabled?: boolean;

  children?: ReactNode;

  className?: string;

  /**
   * A data attribute for testing purposes.
   */
  'data-qa'?: string;
}

const ButtonComponent = <As extends ElementType>(
  {
    as,
    appearance = 'primary',
    wide = false,
    size = 'l',
    icon,
    iconSide,
    iconTheme,
    loading,
    disabled,
    type = 'button',
    children,
    className,
    onClick,
    ...buttonAttrs
  }: ReplaceProps<As, ButtonProps<As>>,
  ref: Ref<unknown>,
) => {
  const Component = (as || 'button') as ElementType;

  const content = [
    loading ? (
      <Loader key="loader" size={ICON_SIZES[size]} />
    ) : (
      icon && (
        <Icon
          className={styles.icon}
          name={icon}
          forcedTheme={iconTheme}
          size={ICON_SIZES[size]}
          key="icon"
        />
      )
    ),
    children && (
      <span className={styles.body} key="body">
        {children}
      </span>
    ),
  ];
  if (iconSide === 'right') {
    content.reverse();
  }

  const effectivelyDisabled = disabled || loading;

  return (
    <Component
      {...buttonAttrs}
      ref={ref}
      type={type}
      disabled={effectivelyDisabled}
      className={classNames(
        className,
        styles.button,
        mappedStyles[appearance],
        sizeStyles[size],
        wide && styles.button_wide,
        { [styles.button_disabled]: effectivelyDisabled },
      )}
      onClick={
        effectivelyDisabled
          ? (event: MouseEvent) => {
              event.preventDefault();
            }
          : onClick
      }
    >
      {content}
    </Component>
  );
};

export const Button: ComponentWithAsProp<'button', ButtonProps> =
  forwardRef(ButtonComponent);
